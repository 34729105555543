import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MailSendt = () => (
  <Layout>
    <SEO title="Mail sendt" />
    <div className="mail_send">
      <h1>Mail sendt afsted</h1>
      <p>Vi sender dig et svar hurtigst muligt</p>

      <Link className="blue_button">Tilbage til forsiden</Link>
    </div>
  </Layout>
)

export default MailSendt
